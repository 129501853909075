<template>
<div class="container">
  <table style="height: 90vh;  margin-top: 100px; ">
    <tbody>
      <td  style="text-align: justify;"> 
        <h1>CONTACT</h1>
      <br>
      <br>
      
      <p>mail: info[at]paridescollective.de</p>
      <p>demos: demos[at]paridescollective.de</p>
      <br>

      </td>

     
    </tbody>
  </table>
</div>
<br>
<br>
<br>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
