<template >
  

    
    <!-- Logo -->
    <!-- <div style="">
      <img style="width: 200px;" class="float-left" src="./assets/Logo.png"><img>
    </div> -->

    <!-- NAVBAR
    main
    ABOUT US
    LABEL
    SHOP
    IMPRINT
    -->


<Transition>
  <div >
    <!-- DESKTOP NAV -->
    <div class="hidden-mobile">
    <nav id="scrollnav" style="padding:2px; " :class="[!Darkmode? 'navbar fixed-top navbar-expand-lg navbar-light bg-light xxl-body-tertiary':'navbar fixed-top navbar-expand-lg navbar-dark bg-dark']">
  <a v-if="this.Darkmode" class="navbar-brand" @click="navClick(0)" href="#"><img style="width: 200px;"  src="./assets/Logo2-inverted.png"><img></a>
  <a v-if="!this.Darkmode" class="navbar-brand" @click="navClick(0)" href="#"><img style="width: 200px;"  src="./assets/Logo2.png"><img></a>
  
  <button style="border:0;" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-target="#navbarText" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span  id="title" >MENU</span> 
                
                <!-- class="navbar-toggler-icon" -->
              </button>
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span>Menu</span>
  </button> -->

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li :class="{'active': this.aboutus}"  class="nav-item ">
        <a class="nav-link "  @click="navClick(4)" href="#aboutus">ABOUT US </a>
      </li>
      <li class="nav-item" :class="{'active': this.label}" >
        <a class="nav-link"  @click="navClick(1)"  href="#">LABEL</a>
      </li>
      <li class="nav-item" :class="{'active': this.shop}" >
        <a class="nav-link" target="_blank"    href="https://www.shop.paridescollective.de">SHOP</a>
        <!-- @click="navClick(2)" -->
      </li>
     
    </ul>
    <span class="navbar-text" style="margin-right: 2vh; margin-left: 2vh;">
      <a href="#" @click="navClick(5)"  placement="bottom" v-b-tooltip.hover title="Contact"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Mail.png"> </a>
      <a href="https://t.me/+QwqflEgAWDs5MjY6" target="_blank" placement="bottom" v-b-tooltip.hover title="Telegram Newsletter"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Telegram.png"> </a>
      <a href="https://ra.co/promoters/127685" placement="bottom"  target="_blank" v-b-tooltip.hover title="Events"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Ra.png"> </a>
      <a href="https://soundcloud.com/paridescollective" placement="bottom" target="_blank" v-b-tooltip.hover title="Latest Sets"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/SoundCloud.png"> </a>
      <a href="https://www.youtube.com/@paridescollective" placement="bottom" target="_blank" v-b-tooltip.hover title="Youtube"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/youtube.png"> </a>
      <a href="https://www.instagram.com/paridescollective/" placement="bottom" target="_blank" v-b-tooltip.hover title="Instagram"><img style="width: 20px;"  src="./assets/icons/Insta.png"> </a>
    </span>
  </div>
</nav>
</div>
<!-- MOBLE NAV -->
<div class="hidden-pc">
<nav  style="padding:2px; " :class="[!Darkmode? 'navbar fixed-top navbar-expand-lg navbar-light bg-light xxl-body-tertiary':'navbar fixed-top navbar-expand-lg navbar-dark bg-dark']">
  <a v-if="this.Darkmode" class="navbar-brand" @click="navClick(0)" href="#"><img style="width: 200px;"  src="./assets/Logo2-inverted.png"><img></a>
  <a v-if="!this.Darkmode" class="navbar-brand" @click="navClick(0)" href="#"><img style="width: 200px;"  src="./assets/Logo2.png"><img></a>
  
  <button style="border:0;" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-target="#navbarText" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span style="font-size:smaller;"  id="title" >MENU</span> 
                
                <!-- class="navbar-toggler-icon" -->
              </button>
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span>Menu</span>
  </button> -->

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li :class="{'active': this.aboutus}"  class="nav-item ">
        
        <a class="nav-link " data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"  @click="navClick(4)" href="#aboutus">ABOUT US </a>
      </li>
      <li class="nav-item" :class="{'active': this.label}" >
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="navClick(1)"  href="#">LABEL</a>
      </li>
      <li class="nav-item" :class="{'active': this.shop}" >
        <a class="nav-link"   target="_blank" href="https://www.shop.paridescollective.de">SHOP</a>
      </li>
     
    </ul>
    <span class="navbar-text" style="margin-right: 2vh; margin-left: 2vh;">
      <a href="#" @click="navClick(5)"  placement="bottom" v-b-tooltip.hover title="Contact"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Mail.png"> </a>
      <a href="https://t.me/+QwqflEgAWDs5MjY6" target="_blank" placement="bottom" v-b-tooltip.hover title="Telegram Newsletter"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Telegram.png"> </a>
      <a href="https://ra.co/promoters/127685" placement="bottom"  target="_blank" v-b-tooltip.hover title="Events"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/Ra.png"> </a>
      <a href="https://soundcloud.com/paridescollective" placement="bottom" target="_blank" v-b-tooltip.hover title="Latest Sets"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/SoundCloud.png"> </a>
      <a href="https://www.youtube.com/@paridescollective" placement="bottom" target="_blank" v-b-tooltip.hover title="Youtube"><img style="width: 20px; margin-right: 1vh;"  src="./assets/icons/youtube.png"> </a>
      <a href="https://www.instagram.com/paridescollective/" placement="bottom" target="_blank" v-b-tooltip.hover title="Instagram"><img style="width: 20px;"  src="./assets/icons/Insta.png"> </a>
      
    </span>
  </div>
</nav>
</div>

</div>
</Transition>



    <!-- <div >
      <ul class="navi">
        <li ><a style="text-decoration:none; color: #2c3e50;"  @click="navClick(0)" href="#">Main</a></li>
        <li ><a style="text-decoration:none; color: #2c3e50;"  @click="navClick(4)" href="#">About us</a></li>
        <li ><a style="text-decoration:none; color: #2c3e50;"  @click="navClick(1)" href="#">Label</a></li>
        <li ><a style="text-decoration:none; color: #2c3e50;"  @click="navClick(2)" href="#">Shop</a></li>
        <li ><a style="text-decoration:none; color: #2c3e50;" @click="navClick(3)" href="#">Imprint</a></li>
      </ul>
    </div> -->
    

   
    
  
      


  <!-- MAIN -->

<Transition>
  <Mainn v-if="aboutus || main"></Mainn>
</Transition>
<div data-spy="scroll" id="aboutus" data-target="#scrollnav" data-offset="0" >
<Transition>
  <Aboutus v-if="aboutus || main"></Aboutus>
</Transition>
</div>

  
    
    <!-- <Transition>
    <Main  ></Main>
  </Transition>

<Transition>
    <Aboutus class="module" v-if="aboutus"></Aboutus>
  </Transition> -->

  <Transition>
    <Label class="module" v-if="label"></Label>
  </Transition>

  <Transition>
    <Shop class="module" v-if="shop"></Shop>
  </Transition>

  <Transition>
    <Imprint class="module" v-if="imprint"></Imprint>
  </Transition>
  
  <Transition>
    <Contact class="module" v-if="contact"></Contact>
  </Transition>
   


  
  
  <footer >
    <a target="_blank" style="text-decoration:none; color:#2c3f50;" href="https://t.me/+QwqflEgAWDs5MjY6">NEWSLETTER</a> <br>
    <a style="text-decoration:none; color:#2c3f50;" @click="navClick(5)" href="#">KONTAKT</a>  //  
    <a style="text-decoration:none; color:#2c3f50;" @click="navClick(3)" href="#">IMPRESSUM</a> <br>
      <!-- <a id="link" style="text-decoration:none; color: #2c3e50;"  @click="navClick(0)" href="#">Datenschutz & AGB</a> -->
     
      @COPYRIGHT PARIDES COLLECTIVE 2023
    
    </footer>


  <button :class="[this.Darkmode? 'colorblack' : 'colorwhite']" class="toggle border" @click="toggleDarkmode()"><img style="width: 25px; "  src="./assets/icons/black-and-white.png">
     </button>
<!-- 🌓 -->

</template>

<script>

import Mainn from './components/Main.vue'
import Shop from './components/Shop.vue'
import Label from './components/Label.vue'
import Imprint from './components/Imprint.vue'
import Aboutus from './components/Aboutus.vue'
import releases from './assets/Releases.json';
import Contact from './components/Contact.vue';



export default {
  name: 'App',
  components: {
    Mainn,
    Aboutus,
    Label,
    Shop,
    Imprint,
    Contact
    



  },

  computed:{

    releasessort: function(){
      releases.sort(this.time_sort);

      // for(let release in releases){
      //     release.cover = new URL("@/assets/cover/"+release.cover, import.meta.url);
      // }
    return releases;

    }
  },
  methods:{

    time_sort(a, b) {
    return new Date(b.releasedate).getTime() - new Date(a.releasedate).getTime();
},

      toggleDarkmode(){
        if(!this.Darkmode){
          const elem = document.getElementById("app");
        elem.style.color = "white";
        elem.style.backgroundColor = "#212529";
        
        document.querySelectorAll('p').forEach(e => e.style.color = "white");
        document.querySelectorAll('a').forEach(e => e.style.color = "white");
        this.background = "cover9+2-black"
        this.backgroundpc = "cover10+black"
       
        
        this.Darkmode = true;
        }else{
          const elem = document.getElementById("app");
        elem.style.color = "#2c3e50";
        elem.style.backgroundColor = "white";
        document.querySelectorAll('p').forEach(e => e.style.color = "black");
        document.querySelectorAll('a').forEach(e => e.style.color = "black");

        this.background = "cover9+2"
        this.backgroundpc = "cover10+"
        this.Darkmode = false;
        
        
        }

        
      },

      

      navClick(x){

        // START main
        if(x==0){
          this.label = false;
          this.shop = false;
          this.main = true;
          this.imprint = false;
          this.aboutus = false;
          this.contact = false;

        }
        // labels
          if(x == 1){
            this.label = true;
            this.shop = false;
            this.main = false;
            this.imprint = false;
            this.aboutus = false;
            this.contact = false;
          }

          // shop
          if(x == 2){
            this.label = false;
            this.shop = true;
            this.main = false;
            this.imprint = false;
            this.aboutus = false;
            this.contact = false;
          }

          // Imprint
          if(x == 3){
            this.label = false;
            this.shop = false;
            this.main = false;
            this.imprint = true;
            this.aboutus = false;
            this.contact = false;
          }

          // aboutus
          if(x == 4){
            this.label = false;
            this.shop = false;
            this.main = false;
            this.imprint = false;
            this.aboutus = true;
            this.contact = false;
          }

          //contact
          if(x == 5){
            this.label = false;
            this.shop = false;
            this.main = false;
            this.imprint = false;
            this.aboutus = false;
            this.contact = true;
          }
          
          return;
      }
      
  },
  data: function() {
    return{
      label: false,
      shop: false,
      main: true,
      imprint: false,
      aboutus: false,
      Darkmode:false,
      contact: false,
      releases,
      background:"cover9+2",
      backgroundpc:"cover10+",


    }
  },
}
</script>

<style>


#app {
  font-family: franklingothicregular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  margin-top: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* zoom: 80%; */

 
  /* background-color: bisque; */
}


footer{
  
  padding-top: 10px;
  padding-left: 20px;
  font-size: 10px;
  padding-bottom: 1%;
  /* position:absolute; */
  /* bottom:0;
   */

}



.toggle{
  
width: 3rem;
height: 3rem;
position: fixed;
border-radius: 50%;
border: none;
right: 31px;
bottom: 31px;
left: unset;
cursor: pointer;
transition: all 0.5s ease;
display: flex;
justify-content: center;
align-items: center;
font-size: smaller;
}
.colorwhite{
  background: white;
}

.colorblack{
  background: #212529;
}



/* DESKTOP */


ul li{
 
  padding-left: 10px;
  color:black;
  font-size: smaller;
}

.navbar-brand{
  padding-left: 20px;
}


/* // .main{
//   float: left;
//   height: 90vh;
//   width:80%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// } */

/* Transitions */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


@media (max-width: 991px) {
        .hidden-mobile {
          display: none;
        }
        
  

      }

@media (min-width: 992px) {
        .hidden-pc {
          display: none;
        }
    
      }
</style>
