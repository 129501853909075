<template>
  <!-- <table style="height: 90vh; width: 90%;">
    <tbody>
      <td class="align-middle text-center">Wir sind das Parides Collective: Ein buntes Gemisch an jungen Menschen, die gemeinsam in Dresden aufgewachsen sind. Mit der Zeit verstreuen wir uns in verschiedene Himmelsrichtungen, doch was uns verbindet, ist die Liebe zu musikalischen Reisen und die gemeinsame Vision kreative Projekte umzusetzen. Anfängliche erste Versuche unter dem Namen „Parides“ selber Musik zu produzieren, entwickelten sich dann zur Idee weiter mehr enthusiastische Menschen ins Boot zu holen und daraus ein kreatives Kollektiv zu gründen, das verschiedenen Künstler:innen eine Plattform bietet, ihre eigenen Kreationen zu teilen. Heute sind wir zwei Hände voll bunt zusammengewürfelter verrückter Köpfe und blicken sowohl zurück als auch nach vorn auf eine begeisternde Palette musikalischer Veröffentlichungen und unvergesslicher Momente mit denen wir versuchen unseren Teil zum Konglomerat der dynamischen Sub-Kultur beizutragen. Ganz essentiell dabei: dem Genre und Stil keine Grenzen zu setzen - ganz nach unserem Motto: 
„C’EST PAS LA MÊME CHOSE.“ </td>
    </tbody>
  </table> -->
 <div class="background">
<div class="container" style="height:100%">
  <br>
  <br>
  <br>
  <br>
  <h1>RELEASES</h1>
  <br>
  <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-xl-5  row-cols-lg-4 g-4 under">

<div v-for="(element, x) in this.$root.releasessort" :key="x" >

<div class="col">
    <a target="_blank" :href="element.link" class="img-hover-zoom ">
    <div style="border-width:0;" class="card border-light bg-transparent ">
      
      <div class=" img-hover-zoom">
      
      <img :src="require(`../assets/cover/${element.cover}.jpeg`)"  style="width: 100%" class="card-img-top " alt="...">
  
    </div>
  
      <div class="card-body">
        <!-- <h5 class="card-title">SAMBA BOUNCE Vol.1 </h5>  -->
        <div class="card-text">
          <p :class="{white: this.$root.Darkmode}">{{ element.title }} {{ this.item }}</p>
          <p :class="{white: this.$root.Darkmode}">{{ element.artist }} </p>
        </div>
      
      </div>  </div>
    </a>
</div>



</div>

 



  
 

  
</div>
<div class="text-center">
  <br>
  <br>


    <!-- <a class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="#" >More</a> -->

  </div>
  
</div>
</div>

<div class="container" style="height:20vh"></div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function(){
    return{
      item : "",
    }
  },
  computed: {

    checkdark(){
      if(this.Darkmode){
        const elem = document.getElementById("app");
        elem.style.color = "#2c3e50";
        elem.style.backgroundColor = "white";
        document.querySelectorAll('p').forEach(e => e.style.color = "black");
        document.querySelectorAll('a').forEach(e => e.style.color = "black");
      }
      return null;

        
        
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-body{
  padding:0;
  margin:0;
  padding: 3px;
}
p{
  padding:0;
  margin:0;
  font-size: small;
}


/* img{
  transition: 0.5s;
}
img:hover{
  filter: brightness(130%);
} */


/* .card:hover{
  background-color: rgb(58, 58, 58);
} */

/* HOOVER */
.img-hover-zoom {
 
  overflow: hidden; 
}


.img-hover-zoom img {
	width: 100%;
	height: auto;
	/* SCALE */
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	/* VERZÖGERUNG */
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.img-hover-zoom img:hover {
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-ms-transform: scale(1.05);
	-o-transform: scale(1.05);
	transform: scale(1.05);
}

.col a{
  text-decoration: none;
}

.white{
  color:white;
}
.col a:hover{
  
  color: black;
  
}

.background{
  /* background-image: url("../assets/paridesevent-22-transpa.png");
  width: 100%;
  height: 85vh; */
}

</style>
