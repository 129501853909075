<template >
  <!-- <div style="background-color: rgba(255, 255, 255, 0.067);" class="">
   <table style="height: 95vh; width: 80%;  margin-left: 17vh;" >
    <tbody>
      <td style="text-align: justify;" >Wir sind das Parides Collective: Ein buntes Gemisch an jungen Menschen, die gemeinsam in Dresden aufgewachsen sind. Mit der Zeit verstreuen wir uns in verschiedene Himmelsrichtungen, doch was uns verbindet, ist die Liebe zu musikalischen Reisen und die gemeinsame Vision kreative Projekte umzusetzen. Anfängliche erste Versuche unter dem Namen „Parides“ selber Musik zu produzieren, entwickelten sich dann zur Idee weiter mehr enthusiastische Menschen ins Boot zu holen und daraus ein kreatives Kollektiv zu gründen, das verschiedenen Künstler:innen eine Plattform bietet, ihre eigenen Kreationen zu teilen. Heute sind wir zwei Hände voll bunt zusammengewürfelter verrückter Köpfe und blicken sowohl zurück als auch nach vorn auf eine begeisternde Palette musikalischer Veröffentlichungen und unvergesslicher Momente mit denen wir versuchen unseren Teil zum Konglomerat der dynamischen Sub-Kultur beizutragen. Ganz essentiell dabei: dem Genre und Stil keine Grenzen zu setzen - ganz nach unserem Motto: 
„C’EST PAS LA MÊME CHOSE.“ </td>
    </tbody>
  </table>
</div> -->
<div class="background">
<!-- <div class="container " style=" height:70vh">
  <table style="height: 70vh;" >
    <tbody>
      
      <td style="text-align: justify;" > <h1>ABOUT US</h1>
        <br>
        Wir sind das Parides Collective: Ein buntes Gemisch an jungen Menschen, die gemeinsam in Dresden aufgewachsen sind. Mit der Zeit verstreuen wir uns in verschiedene Himmelsrichtungen, doch was uns verbindet, ist die Liebe zu musikalischen Reisen und die gemeinsame Vision kreative Projekte umzusetzen. Anfängliche erste Versuche unter dem Namen „Parides“ selber Musik zu produzieren, entwickelten sich dann zur Idee weiter mehr enthusiastische Menschen ins Boot zu holen und daraus ein kreatives Kollektiv zu gründen, das verschiedenen Künstler:innen eine Plattform bietet, ihre eigenen Kreationen zu teilen. Heute sind wir zwei Hände voll bunt zusammengewürfelter verrückter Köpfe und blicken sowohl zurück als auch nach vorn auf eine begeisternde Palette musikalischer Veröffentlichungen und unvergesslicher Momente mit denen wir versuchen unseren Teil zum Konglomerat der dynamischen Sub-Kultur beizutragen. Ganz essentiell dabei: dem Genre und Stil keine Grenzen zu setzen - ganz nach unserem Motto: 
„C’EST PAS LA MÊME CHOSE.“ 
<br>
<br>
<br>

</td>

    </tbody>
  </table>

</div> -->

<div class="container hidden-mobile" style="height:20vh"></div>
<div class="container hidden-pc" style="height:5vh"></div>
<div class="container">
  <div style="text-align: justify;" >
    <h1 class="hidden-mobile">ABOUT US</h1>
    <h1 style="font-size:larger" class="hidden-pc">ABOUT US</h1>
        <br class="hidden-mobile">
        We are the Parides Collective: A young group of various individuals who grew up together in Dresden. Over time, we spread out in different directions, but what binds us is our love for musical journeys and our shared vision to execute creative projects. Initial attempts to produce music under the name "Parides" evolved into the idea of bringing more enthusiastic people on board to establish a creative collective that provides a platform for various artists to share their own creations. Today, we are a handful of eclectic minds, looking both back and forward to an exciting array of musical releases and unforgettable moments, as we strive to contribute our part to the conglomerate of dynamic sub-culture. A fundamental aspect of our approach is to not limit ourselves by genre or style - in line with our motto: "C’EST PAS LA MÊME CHOSE."
        <!-- Wir sind das Parides Collective: Ein buntes Gemisch an jungen Menschen, die gemeinsam in Dresden aufgewachsen sind. Mit der Zeit verstreuen wir uns in verschiedene Himmelsrichtungen, doch was uns verbindet, ist die Liebe zu musikalischen Reisen und die gemeinsame Vision kreative Projekte umzusetzen. Anfängliche erste Versuche unter dem Namen „Parides“ selber Musik zu produzieren, entwickelten sich dann zur Idee weiter mehr enthusiastische Menschen ins Boot zu holen und daraus ein kreatives Kollektiv zu gründen, das verschiedenen Künstler:innen eine Plattform bietet, ihre eigenen Kreationen zu teilen. Heute sind wir zwei Hände voll bunt zusammengewürfelter verrückter Köpfe und blicken sowohl zurück als auch nach vorn auf eine begeisternde Palette musikalischer Veröffentlichungen und unvergesslicher Momente mit denen wir versuchen unseren Teil zum Konglomerat der dynamischen Sub-Kultur beizutragen. Ganz essentiell dabei: dem Genre und Stil keine Grenzen zu setzen - ganz nach unserem Motto: 
„C’EST PAS LA MÊME CHOSE.“  -->
<div class="container" style="height:10vh"></div>
<div class="container hidden-mobile" style="height:10vh"></div>
<!-- <h1>LATEST</h1> -->


    <h1 style="font-size:larger; margin-bottom: 15px; color: grey;" class="">// ÄQUINOKTIUM</h1>


<div style="aspect-ratio: 4 / 2   ;">
<iframe width="100%" height="100%" src="https://www.youtube.com/embed/dyTIrdzxQzs?si=xFMF74tUK0p8pE4p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
<br>
<br>
<br>


<!-- <h3>// Und los - rémi.fr</h3>

<div class="container" style="aspect-ratio: 4 / 2   ;">
<iframe width="100%" height="100%" src="https://www.youtube.com/embed/_AMu7HvrOAE?si=6JsvYV_kT32ZvpWv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>

<br>
<br>
<h3>// MIO | Sunset Set @ Balkon | 30/09/23</h3>

<div class="container" style="aspect-ratio: 4 / 2   ;">
<iframe width="100%" height="100%" src="https://www.youtube.com/embed/tRxE5azLbjY?si=vf4DW3F0_aX7d5pN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div> -->
  </div>

  <div class="row">
    <div class="col-md" >
      <h1 style="font-size:larger; margin-bottom: 15px; color: grey;" class="">// PODCASTS</h1>
  
      <iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1623282055&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/paridescollective" title="PARIDES COLLECTIVE" target="_blank" style="color: #cccccc; text-decoration: none;">PARIDES COLLECTIVE</a> · <a href="https://soundcloud.com/paridescollective/sets/parides-recordings" title="PARIDES PODCASTS" target="_blank" style="color: #cccccc; text-decoration: none;">PARIDES PODCASTS</a></div>
   <br>
   <br>
   <br>
    </div>
    <div class="col-md">
      <h1 style="font-size:larger; margin-bottom: 15px; color: grey;" class="">// RELEASES</h1>
     
      <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/1UAE5bp20oOv60IYHup26r?utm_source=generator&theme=0" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
  </div>
</div>

</div>
<div class="container" style="height:20vh"></div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3{
  color:grey;
}
</style>
