
<template>
  <!-- <div class="background">
  
  <table style="height: 90vh; width: 90%;">
    <tbody>
      <td  >
      
    
        <p style="text-align: justify;">
           
        </p> 
      </td>
    </tbody>
  </table>

</div> -->
<img class="img-fluid hidden-mobile"  :src="require(`../assets/${this.$root.backgroundpc}.jpg`)">
<Transition>
<img class="img-fluid hidden-pc"  :src="require(`../assets/${this.$root.background}.jpg`)">
</Transition>




</template>

<script >

export default {
  name: 'HelloWorld',
  components:{
    
  }
  
  ,
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background{
  background-image: url("../assets/cover6+.png");
  width: 100%;
  height: 100vh;
}

</style>
