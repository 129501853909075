// import '@babel/polyfill'
// import 'mutationobserver-shim'
// import './plugins/bootstrap-vue'
import { createApp } from 'vue'
import App from './App.vue'
// import BootstrapVue3 from 'bootstrap-vue-3'
import BootstrapVue3 from 'bootstrap-vue-next'
// import * as Vue from 'vue'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'



// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

const app = createApp(App)
app.use(BootstrapVue3)
app.mount('#app')
